import { getColor } from '@utilities';
import { get, unescape } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import { HawkSearchComponents, HawkSearchGlobal } from '@configuration';
import { ItemVariant, RawVariantDocument } from '@models';

declare let HawkSearch: HawkSearchGlobal;

export interface FieldMappings {
    description: Array<string>;
    imageUrl: Array<string>;
    price: Array<string>;
    rating: Array<string>;
    salePrice: Array<string>;
    sku: Array<string>;
    title: Array<string>;
    type: Array<string>;
    url: Array<string>;
}

export interface VariantFieldMappings {
    color: {
        name: Array<string>;
        hex: Array<string>;
        imageUrl: Array<string>;
    };
    description: Array<string>;
    id: Array<string>;
    imageUrl: Array<string>;
    price: Array<string>;
    rating: Array<string>;
    salePrice: Array<string>;
    sku: Array<string>;
    title: Array<string>;
    url: Array<string>;
}

export abstract class BaseService {
    protected abstract baseUrl: string;
    protected fieldMappings: FieldMappings;
    protected variantFieldMappings: VariantFieldMappings;

    public queryStringParams = {
        disableSpellcheck: HawkSearch.config.search?.queryStringMappings?.disableSpellcheck || 'disableSpellcheck',
        page: HawkSearch.config.search?.queryStringMappings?.page || 'page',
        pageSize: HawkSearch.config.search?.queryStringMappings?.pageSize || 'pageSize',
        query: HawkSearch.config.search?.queryStringMappings?.query || 'query',
        searchWithin: HawkSearch.config.search?.queryStringMappings?.searchWithin || 'searchWithin',
        sort: HawkSearch.config.search?.queryStringMappings?.sort || 'sort'
    };

    public searchUrl = HawkSearch.config.search?.url ?? '/search';

    protected get onSearchPage(): boolean {
        const trailingSlashRegex = /\/+$/;

        return window.location.pathname.replace(trailingSlashRegex, '') === this.searchUrl.replace(trailingSlashRegex, '');
    }

    constructor() {
        this.fieldMappings = {
            description: this.getFieldMappings(HawkSearch.config.fieldMappings?.description, ['description', 'longdescription']),
            imageUrl: this.getFieldMappings(HawkSearch.config.fieldMappings?.imageUrl, ['imageurl', 'image']),
            price: this.getFieldMappings(HawkSearch.config.fieldMappings?.price, ['price']),
            rating: this.getFieldMappings(HawkSearch.config.fieldMappings?.rating, ['rating']),
            salePrice: this.getFieldMappings(HawkSearch.config.fieldMappings?.salePrice, ['saleprice']),
            sku: this.getFieldMappings(HawkSearch.config.fieldMappings?.sku, ['sku']),
            title: this.getFieldMappings(HawkSearch.config.fieldMappings?.title, ['title', 'name', 'itemname']),
            type: this.getFieldMappings(HawkSearch.config.fieldMappings?.type, ['type']),
            url: this.getFieldMappings(HawkSearch.config.fieldMappings?.url, ['url'])
        };

        this.variantFieldMappings = {
            color: {
                name: this.getFieldMappings(
                    HawkSearch.config.variants?.fieldMappings?.color?.name,
                    ['colorname', 'color'],
                    HawkSearch.config.variants?.fieldPrefix
                ),
                hex: this.getFieldMappings(
                    HawkSearch.config.variants?.fieldMappings?.color?.hex,
                    ['colorhex', 'colorvalue'],
                    HawkSearch.config.variants?.fieldPrefix
                ),
                imageUrl: this.getFieldMappings(
                    HawkSearch.config.variants?.fieldMappings?.color?.imageUrl,
                    ['colorimageurl', 'colorimage'],
                    HawkSearch.config.variants?.fieldPrefix
                )
            },
            id: this.getFieldMappings(HawkSearch.config.variants?.fieldMappings?.id, ['id'], HawkSearch.config.variants?.fieldPrefix),
            imageUrl: this.getFieldMappings(
                HawkSearch.config.variants?.fieldMappings?.imageUrl,
                ['imageurl', 'image'],
                HawkSearch.config.variants?.fieldPrefix
            ),
            price: this.getFieldMappings(HawkSearch.config.variants?.fieldMappings?.price, ['price'], HawkSearch.config.variants?.fieldPrefix),
            rating: this.getFieldMappings(HawkSearch.config.variants?.fieldMappings?.rating, ['rating'], HawkSearch.config.variants?.fieldPrefix),
            salePrice: this.getFieldMappings(
                HawkSearch.config.variants?.fieldMappings?.salePrice,
                ['salePrice', 'saleprice'],
                HawkSearch.config.variants?.fieldPrefix
            ),
            sku: this.getFieldMappings(HawkSearch.config.variants?.fieldMappings?.sku, ['title', 'name', 'itemname'], HawkSearch.config.variants?.fieldPrefix),
            title: this.getFieldMappings(
                HawkSearch.config.variants?.fieldMappings?.title,
                ['title', 'name', 'itemname'],
                HawkSearch.config.variants?.fieldPrefix
            ),
            description: this.getFieldMappings(
                HawkSearch.config.variants?.fieldMappings?.description,
                ['description', 'longdescription'],
                HawkSearch.config.variants?.fieldPrefix
            ),
            url: this.getFieldMappings(HawkSearch.config.variants?.fieldMappings?.url, ['url'], HawkSearch.config.variants?.fieldPrefix)
        };
    }

    // #region HTTP

    protected async httpPost<T>(relativeUrl: string, body?: any): Promise<T> {
        const url = this.baseUrl + relativeUrl;
        const headers = this.getHeaders();

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        });

        const contentType = response.headers.get('content-type');

        if (contentType?.startsWith('application/json')) {
            return response.json();
        } else {
            return undefined as unknown as T;
        }
    }

    private getHeaders(): Headers {
        const headers = new Headers();

        headers.set('Content-Type', 'application/json');
        headers.set('X-Requested-With', 'XMLHttpRequest');

        return headers;
    }

    // #endregion HTTP

    // #region Field Mappings

    private getFieldMappings(values: string | Array<string> | undefined, defaultValues: Array<string>, prefix?: string): Array<string> {
        if (typeof values === 'string') {
            values = [values];
        }

        if (!values?.length) {
            values = defaultValues;
        }

        if (prefix) {
            values = values.map((v) => prefix + v);
        }

        return values;
    }

    // #endregion Field Mappings

    // #region Tracking

    protected getVisitorId(): string {
        const key = 'VisitorId';
        let value = localStorage.getItem(key);

        if (!value) {
            value = this.generateGuid();

            localStorage.setItem(key, value);
        }

        return value;
    }

    protected getVisitId(): string {
        const key = 'VisitId';
        let value = sessionStorage.getItem(key);

        if (!value) {
            value = this.generateGuid();

            sessionStorage.setItem(key, value);
        }

        return value;
    }

    protected generateGuid(): string {
        return uuid();
    }

    // #endregion Tracking

    // #region Data Parsing

    protected stripHtml(input: string): string {
        let output = input.replace(/<[^>]+>/g, '');

        output = unescape(output);
        output = decodeURIComponent(output);

        return output;
    }

    protected getValue(data: object, fieldMappings: Array<string>): Array<string> | undefined {
        for (const fieldMapping of fieldMappings) {
            let value = get(data, fieldMapping);

            if (value) {
                if (typeof value === 'string') {
                    value = value.split('|^|');
                }

                if (value.length === 0) {
                    continue;
                }

                return value;
            }
        }

        return undefined;
    }

    protected getString(data: object, fieldMappings: Array<string>, defaultValue?: string): string | undefined {
        const value = this.getValue(data, fieldMappings)?.[0];

        return value ?? defaultValue;
    }

    protected getNumber(data: object, fieldMappings: Array<string>, defaultValue?: number): number | undefined {
        const string = this.getValue(data, fieldMappings)?.[0];
        let value = string ? parseFloat(string) : undefined;

        if (value !== undefined && isNaN(value)) {
            value = undefined;
        }

        return value ?? defaultValue;
    }

    protected getUrl(data: object, fieldMappings: Array<string>, prefix?: string | undefined): string | undefined {
        const url = this.getValue(data, fieldMappings)?.[0];

        return this.getFullUrl(url, prefix);
    }

    protected getFullUrl(url: string | undefined, prefix: string | undefined): string | undefined {
        if (!url) {
            return undefined;
        }

        if (prefix && !/^(?:https?:)?\/\//.test(url)) {
            return `${prefix}/${url.replace(/^\/+/, '')}`;
        }

        return url;
    }

    // #endregion Data Parsing

    // #region Variants

    protected getVariants(document: RawVariantDocument): { items: Array<ItemVariant> | undefined; selectedItem?: ItemVariant } {
        if (HawkSearch.config.variants?.enabled === false) {
            return {
                items: undefined,
                selectedItem: undefined
            };
        }

        const fieldPrefix = HawkSearch.config.variants?.fieldPrefix;
        const variantFieldRegex = fieldPrefix ? new RegExp('^' + fieldPrefix) : undefined;

        const getVariantColor = (variant: Record<string, Array<string>>) => {
            const name = this.getString(variant, this.variantFieldMappings.color.name);
            const imageUrl = this.getString(variant, this.variantFieldMappings.color.imageUrl);
            let hex = this.getString(variant, this.variantFieldMappings.color.hex);

            if (!hex && name) {
                hex = getColor(name);
            }

            if (!hex && !imageUrl) {
                return undefined;
            }

            return {
                name: name,
                hex: hex,
                imageUrl: imageUrl
            };
        };

        const hitVariantIds =
            document.hawk_child_attributes_hits?.[0]?.Items?.reduce((variantIds, hit) => {
                const id = this.getString(hit, this.variantFieldMappings.id);

                if (id) {
                    variantIds.push(id);
                }

                return variantIds;
            }, [] as Array<string>) ?? [];

        let items: Array<ItemVariant> | undefined = document.hawk_child_attributes
            ?.map((variant, index) => {
                const id = this.getString(variant, this.variantFieldMappings.id) ?? '';

                return {
                    attributes: Object.fromEntries(
                        Object.entries(variant).map(([key, value]) => {
                            if (variantFieldRegex) {
                                key = key.replace(variantFieldRegex, '');
                            }

                            return [key, value];
                        })
                    ),
                    color: getVariantColor(variant),
                    description: this.getString(variant, this.variantFieldMappings.description),
                    id: id,
                    imageUrl: this.getString(variant, this.variantFieldMappings.imageUrl),
                    price: this.getNumber(variant, this.variantFieldMappings.price) || undefined,
                    queryMatch: hitVariantIds.includes(id),
                    rating: this.getNumber(variant, this.variantFieldMappings.rating),
                    salePrice: this.getNumber(variant, this.variantFieldMappings.salePrice) || undefined,
                    title: this.getString(variant, this.variantFieldMappings.title),
                    url: this.getString(variant, this.variantFieldMappings.url)
                };
            })
            .filter((v) => !!v.id);

        if (!items?.length) {
            items = undefined;
        }

        let selectedItem: ItemVariant | undefined = undefined;

        for (const id of hitVariantIds) {
            const variant = items?.find((v) => v.id === id);

            if (variant) {
                selectedItem = variant;

                break;
            }
        }

        if (!selectedItem) {
            selectedItem = items?.[0];
        }

        return {
            items: items,
            selectedItem: selectedItem
        };
    }

    // #endregion Variants

    // #region Events

    protected triggerBindEvent<T>(component: keyof HawkSearchComponents, data: T, filter?: string): void {
        let eventName = `hawksearch:bind-${component}`;

        if (filter) {
            eventName = `${eventName}:${filter}`;
        }

        this.triggerEvent(eventName, data);
    }

    protected triggerEvent<T>(name: string, data: T): void {
        const event = new CustomEvent(name, { detail: data });

        window.dispatchEvent(event);
    }

    // #endregion Events
}
