import { HawkSearchGlobal } from '@configuration';
import {
    AutocompleteProductResult,
    AutocompleteRequest,
    AutocompleteResponse,
    RawAutocompleteProduct,
    RawAutocompleteRequest,
    RawAutocompleteResponse
} from '@models';
import { BaseService } from '@services';
import { decodeNestedURI } from '@utilities';

declare let HawkSearch: HawkSearchGlobal;

export class AutocompleteService extends BaseService {
    protected baseUrl = HawkSearch.config.autocomplete?.endpointUrl || HawkSearch.config.search?.endpointUrl || 'https://searchapi-dev.hawksearch.net';

    private decodeQuery = HawkSearch.config.autocomplete?.decodeQuery || true;

    // #region Search Operations

    async query(query?: string): Promise<AutocompleteResponse> {
        const request: AutocompleteRequest = {
            query: query
        };

        const response = await this.executeAutocomplete(request);

        return response;
    }

    private async executeAutocomplete(autocompleteRequest: AutocompleteRequest): Promise<AutocompleteResponse> {
        if (this.decodeQuery && autocompleteRequest.query) {
            autocompleteRequest.query = decodeNestedURI(autocompleteRequest.query);
        }
        const rawAutocompleteRequest: RawAutocompleteRequest = {
            ClientGuid: HawkSearch.config.clientId,
            IndexName: HawkSearch.config.index,
            Keyword: autocompleteRequest.query,
            DisplayFullResponse: true
        };

        try {
            this.triggerEvent('hawksearch:before-autocomplete-executed', rawAutocompleteRequest);

            const rawAutocompleteResponse = await this.httpPost<RawAutocompleteResponse>('/api/v2/autocomplete', rawAutocompleteRequest);

            this.triggerEvent('hawksearch:after-autocomplete-executed', rawAutocompleteResponse);

            const autocompleteResponse = this.convertResponse(autocompleteRequest.query, rawAutocompleteResponse);

            this.triggerEvent('hawksearch:autocomplete-completed', autocompleteResponse);

            this.bindComponent(autocompleteResponse);

            return autocompleteResponse;
        } catch {
            throw new Error('Error retrieving autocomplete response');
        }
    }

    private convertResponse(query: string | undefined, rawAutocompleteResponse: RawAutocompleteResponse): AutocompleteResponse {
        const getProductResult = (product: RawAutocompleteProduct): AutocompleteProductResult => {
            const variants = this.getVariants(product.Results.Document);

            return {
                attributes: Object.fromEntries(
                    Object.entries(product.Results.Document).filter(([key, value]) => !['hawk_child_attributes', 'hawk_child_attributes_hits'].includes(key))
                ) as any,
                description: this.getString(product.Results.Document, this.fieldMappings.description),
                id: product.Results.DocId,
                imageUrl: product.Thumb?.Url || this.getUrl(product.Results.Document, this.fieldMappings.imageUrl, HawkSearch.config.urlPrefixes?.content),
                pinned: product.Results.IsPin,
                price: this.getNumber(product.Results.Document, this.fieldMappings.price) || undefined,
                rating: this.getNumber(product.Results.Document, this.fieldMappings.rating),
                salePrice: this.getNumber(product.Results.Document, this.fieldMappings.salePrice) || undefined,
                score: product.Results.Score,
                selectedVariant: variants.selectedItem,
                sku: product.Sku || this.getString(product.Results.Document, this.fieldMappings.sku),
                title: this.stripHtml(product.ProductName) || this.getString(product.Results.Document, this.fieldMappings.title)!,
                url: product.Url || this.getUrl(product.Results.Document, this.fieldMappings.url, HawkSearch.config.urlPrefixes?.content)!,
                variants: variants.items
            };
        };

        return {
            query: query,
            categories: {
                title: rawAutocompleteResponse.CategoryHeading,
                results: rawAutocompleteResponse.Categories.map((c) => ({
                    title: this.stripHtml(c.Value),
                    field: c.FieldQSName,
                    value: decodeNestedURI(c.FieldQSValue),
                    url: `${this.searchUrl}?${c.FieldQSName}=${decodeNestedURI(encodeURIComponent(c.FieldQSValue))}`
                }))
            },
            content: {
                title: rawAutocompleteResponse.ContentHeading,
                results: rawAutocompleteResponse.Content.map((c) => ({
                    id: c.Results.DocId,
                    pinned: c.Results.IsPin,
                    score: c.Results.Score,
                    attributes: c.Results.Document,
                    title: this.stripHtml(c.Value),
                    url: c.Url
                })),
                totalRecords: rawAutocompleteResponse.ContentCount
            },
            queries: {
                title: rawAutocompleteResponse.PopularHeading,
                results: rawAutocompleteResponse.Popular.map((r) => ({
                    query: this.stripHtml(r.RawValue),
                    url: `${this.searchUrl}?${this.queryStringParams.query}=${encodeURIComponent(this.stripHtml(r.RawValue))}`
                }))
            },
            contentSuggestedQueries: {
                title: rawAutocompleteResponse.DYMContentHeading,
                results:
                    rawAutocompleteResponse.DymContentSearch?.map((r) => ({
                        query: this.stripHtml(r.RawValue),
                        url: `${this.searchUrl}?${this.queryStringParams.query}=${encodeURIComponent(this.stripHtml(r.RawValue))}`
                    })) ?? []
            },
            productSuggestedQueries: {
                title: rawAutocompleteResponse.DYMProductHeading,
                results:
                    rawAutocompleteResponse.DymProductsSearch?.map((r) => ({
                        query: this.stripHtml(r.RawValue),
                        url: `${this.searchUrl}?${this.queryStringParams.query}=${encodeURIComponent(this.stripHtml(r.RawValue))}`
                    })) ?? []
            },
            products: {
                title: rawAutocompleteResponse.ProductHeading,
                results: rawAutocompleteResponse.Products.map((p) => getProductResult(p)),
                totalRecords: rawAutocompleteResponse.ProductCount
            },
            totalRecords: rawAutocompleteResponse.Count,
            viewAllText: rawAutocompleteResponse.ViewAllButtonLabel
        };
    }

    // #endregion Search Operations

    // #region Events

    bindComponent(autocompleteResponse: AutocompleteResponse): void {
        this.triggerBindEvent('autocomplete', autocompleteResponse);
    }

    // #endregion Events
}

export const autocompleteService = new AutocompleteService();
