import { assign, get } from 'lodash-es';
import { BaseComponentConfig, HawkSearchComponents, HawkSearchGlobal } from '@configuration';
import { BaseComponentModel } from '@models';
import defaultStylesheet from '../scss/styles.scss';

declare let HawkSearch: HawkSearchGlobal;

/**
 * @typeParam TConfig Component configuration
 * @typeParam TData Component data
 * @typeParam TModel Data bound to Handlebars template
 *
 * @noInheritDoc
 * @category Base
 */
export abstract class BaseComponent<TConfig extends BaseComponentConfig, TData, TModel extends BaseComponentModel> extends HTMLElement {
    /**
     * Optional instance-level configuration to override the global configuration
     */
    configOverride?: TConfig;

    /**
     * The data bound to the component.
     */
    data?: TData;

    /**
     * The Handlebars reference shared by all HawkSearch components.
     */
    protected handlebars = HawkSearch.handlebars;

    /**
     * For components that may have multiple instances with different data sources, this property is used to identify the subset of data used for binding.
     *
     * @internal
     */
    protected eventFilter?: string;

    /**
     * The data bound to the Handlebars template.
     */
    protected contentModel?: TModel;

    /**
     * The name of the component. This value is appended to `hawksearch-` to determine the tag name. For example, a value of `search-results` would be rendered by `<hawksearch-search-results>`.
     *
     * @internal
     */
    protected abstract componentName: keyof HawkSearchComponents;

    /**
     * The default Handlebars template.
     *
     * @internal
     */
    protected abstract defaultHtml: string;

    /**
     * Determines whether the component is bound by an event. If `false`, binding is performed by the parent component.
     *
     * @internal
     */
    protected abstract bindFromEvent: boolean;

    /**
     * The optional configuration object for this component.
     */
    protected get configuration(): TConfig | undefined {
        const globalConfig = HawkSearch.config.components?.[this.componentName] as TConfig | undefined;
        const configurationAttribute = this.getAttribute('config');

        if (!configurationAttribute && !this.configOverride) {
            return globalConfig;
        }

        let instanceConfig: TConfig | undefined;

        if (configurationAttribute) {
            instanceConfig = get(window, configurationAttribute) ?? undefined;
        } else if (this.configOverride) {
            instanceConfig = this.configOverride;
        }

        return assign({}, globalConfig ?? {}, instanceConfig);
    }

    /**
     * Whether or not the component is rendered within the Shadow DOM. In order of preference, this is specified by {@link configuration} or {@link Models.HawkSearchConfig.shadowDom}.
     *
     * @internal
     * @defaultValue `true`
     */
    protected get shadowDom(): boolean {
        return this.configuration?.shadowDom !== undefined ? this.configuration.shadowDom : HawkSearch.config.shadowDom ?? true;
    }

    /**
     * The root element which should be used for querying any child elements. This resolves to `this.shadowRoot` if the Shadow DOM is enabled, otherwise `this`.
     */
    public get rootElement(): ParentNode {
        return this.shadowRoot ?? this;
    }

    /**
     * The name of the event that this component listens to for data binding. if {@link bindFromEvent} is `true`, this prepends `hawksearch:bind-` to {@link componentName} and appends :{@link eventFilter}, if specified.
     *
     * @internal
     */
    private get eventName(): string | undefined {
        if (!this.bindFromEvent) {
            return undefined;
        }

        let eventName = `hawksearch:bind-${this.componentName}`;

        if (this.eventFilter) {
            eventName = `${eventName}:${this.eventFilter}`;
        }

        return eventName;
    }

    /**
     * @internal
     */
    private bindEventHandler?: EventListener;

    /**
     * @internal
     */
    private template!: HandlebarsTemplateDelegate;

    /**
     * @ignore
     */
    connectedCallback(): void {
        if (this.shadowDom) {
            this.attachShadow({
                mode: 'open'
            });
        }

        this.registerHelpers();

        const customTemplateHtml = this.getCustomTemplateHtml();
        const alternateSyntaxRegex = /\[\[((?!\[).+?)\]\]/g;
        let templateHtml = customTemplateHtml ?? this.defaultHtml;

        while (alternateSyntaxRegex.test(templateHtml)) {
            templateHtml = templateHtml.replace(alternateSyntaxRegex, '{{$1}}');
        }

        this.template = this.handlebars.compile(templateHtml);

        setTimeout(() => {
            this.render();
        });

        if (this.eventName) {
            this.bindEventHandler = ((event: CustomEvent): void => {
                this.data = event.detail;

                this.render();
            }) as EventListener;

            window.addEventListener(this.eventName, this.bindEventHandler);
        }
    }

    /**
     * @ignore
     */
    disconnectedCallback(): void {
        if (this.eventName && this.bindEventHandler) {
            window.removeEventListener(this.eventName, this.bindEventHandler);
        }
    }

    /**
     * Optional method that can be overwritten to register Handlebars helper functions which can be accessed from the template. For more information, see [Custom Helpers](https://handlebarsjs.com/guide/#custom-helpers).
     *
     * @virtual
     */
    protected registerHelpers(): void {}

    /**
     * Binds {@link contentModel} to the Handlebars template and renders the resulting HTML content.
     */
    public render(): void {
        if (!this.template || !this.renderContent()) {
            this.rootElement.replaceChildren();
            this.style.display = 'none';

            return;
        }

        const contentModel = this.getContentModel();

        const beforeEvent = new CustomEvent(`hawksearch:before-${this.componentName}-rendered`, {
            detail: {
                component: this,
                contentModel: contentModel
            }
        });

        dispatchEvent(beforeEvent);

        const elements: Array<Node> = [];
        let stylesheets: Array<string> = [];

        if (HawkSearch.config.css?.defaultStyles !== false) {
            stylesheets.push(defaultStylesheet);
        }

        if (HawkSearch.config.css?.customStyles) {
            const customStylesheets =
                typeof HawkSearch.config.css.customStyles === 'string' ? [HawkSearch.config.css.customStyles] : HawkSearch.config.css.customStyles;

            stylesheets = stylesheets.concat(customStylesheets);
        }

        stylesheets.forEach((item, index) => {
            const id = `hawksearch-handlebars-ui-css-${index}`;

            if (!this.shadowDom && document.head.querySelector(`[id="${id}"]`)) {
                return;
            }

            let node: Node | undefined = undefined;

            if (/^.*?.css$/.test(item)) {
                const linkElement = document.createElement('link');

                linkElement.id = id;
                linkElement.type = 'text/css';
                linkElement.rel = 'stylesheet';
                linkElement.href = item;

                node = linkElement;
            } else {
                const element = document.getElementById(item);

                if (element instanceof HTMLTemplateElement) {
                    const templateContent = element.content.cloneNode(true) as HTMLElement;
                    const styleElement = templateContent.querySelector('style');

                    if (styleElement) {
                        styleElement.id = id;

                        node = styleElement;
                    }
                } else {
                    const styleElement = document.createElement('style');

                    styleElement.id = id;
                    styleElement.innerHTML = item;

                    node = styleElement;
                }
            }

            if (!node) {
                return;
            }

            if (this.shadowDom) {
                elements.push(node);
            } else {
                document.head.append(node);
            }
        });

        const templateElement = document.createElement('template');
        const html = this.template(contentModel);

        templateElement.innerHTML = html;

        const content = templateElement.content.cloneNode(true);

        elements.push(content);

        this.rootElement.replaceChildren(...elements);
        this.style.display = !templateElement.innerHTML ? 'none' : '';

        this.contentModel = contentModel;

        this.bindChildElements();
        this.onRender();

        const afterEvent = new CustomEvent(`hawksearch:after-${this.componentName}-rendered`, {
            detail: {
                component: this,
                contentModel: contentModel
            }
        });

        dispatchEvent(afterEvent);
    }

    /**
     * Determines whether the {@link data} meets the necessary conditions to perform data binding and render content.
     *
     * @virtual
     * @returns Whether the component should be rendered. If `false`, the component will have empty contents and be set to `display: none;`.
     */
    protected renderContent(): boolean {
        return true;
    }

    /**
     * Gets the data to be bound to the Handlebars template.
     *
     * @internal
     * @returns The data bound to the Handlebars template.
     */
    protected abstract getContentModel(): TModel;

    /**
     * After the component is rendered, this method is called to bind any child components.
     *
     * @virtual
     */
    protected bindChildElements(): void {}

    /**
     * After the component is rendered, this method is called for any additional processing (such as attaching event listeners) which needs to occur.
     *
     * @virtual
     */
    protected onRender(): void {}

    /**
     * @returns The Handlebars template HTML. In order of preference, this is specified by the child contents of the HTML element, the template specified by {@link configuration}, or the default template for the component.
     *
     * @internal
     */
    private getCustomTemplateHtml(): string | undefined {
        let customTemplate = this.configuration?.template;

        if (customTemplate) {
            const customTemplateElement = document.getElementById(customTemplate);

            if (customTemplateElement instanceof HTMLTemplateElement || customTemplateElement instanceof HTMLScriptElement) {
                customTemplate = customTemplateElement.innerHTML;
            }

            const replacements = {
                '{{&gt;': '{{>'
            };

            Object.entries(replacements).forEach(([input, output]) => {
                const regex = new RegExp(input, 'g');

                customTemplate = customTemplate!.replace(regex, output);
            });
        }

        return customTemplate;
    }

    /**
     * Replaces placeholders in a given string with values from a data object.
     *
     * @param template The template string.
     * @param values The object containing properties which will be bound to `template`.
     * @returns The `template` string with all placeholders replaced by the values specified in `values`.
     */
    protected interpolate(template: string, values: Record<string, string>): string {
        let output = template;

        Object.entries(values).forEach(([key, value]) => {
            output = output.replace('${' + key + '}', value);
        });

        return output;
    }
}
