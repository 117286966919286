import { datesEqual, formatDateIso } from '@utilities';
import { DateRangeFacetComponentConfig, HawkSearchComponents } from '@configuration';
import { DateFacetRange, DateRangeFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './date-range-facet.component.hbs';

/**
 * The Date Range Facet component renders number input elements for minimum and maximum value.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-date-range-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-start | |
 * | hawksearch-end | |
 *
 * These attributes should be placed on {@link Components.DatePickerComponent | Date Picker} elements. When these elements lose focus (blurred), the entered range will be applied as a facet value.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./date-range-facet.component.hbs}
 *
 * @category Facet Types
 */
export class DateRangeFacetComponent extends BaseFacetComponent<DateRangeFacetComponentConfig, DateRangeFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'date-range-facet';
    protected override defaultHtml = defaultHtml;

    private get range(): DateFacetRange | undefined {
        if (this.data?.range?.type === 'date') {
            return this.data.range;
        }

        return undefined;
    }

    protected override renderContent(): boolean {
        return !!this.range;
    }

    protected override getContentModel(): DateRangeFacetComponentModel {
        return {
            startValue: this.range!.start.toISOString(),
            endValue: this.range!.end.toISOString(),
            minValue: this.range!.min.toISOString(),
            maxValue: this.range!.max.toISOString(),
            strings: {
                startDate: this.configuration?.strings?.startDate ?? 'Start Date',
                endDate: this.configuration?.strings?.endDate ?? 'End Date'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-start]').forEach((e) => {
            e.addEventListener('hawksearch:date-picker-changed', ((event: CustomEvent) => {
                let value: Date | undefined = event.detail;

                if (!value) {
                    value = this.range!.min;
                }

                this.setFacetValue(value, this.range!.end);
            }) as EventListener);
        });

        this.rootElement.querySelectorAll('[hawksearch-end]').forEach((e) => {
            e.addEventListener('hawksearch:date-picker-changed', ((event: CustomEvent) => {
                let value: Date | undefined = event.detail;

                if (!value) {
                    value = this.range!.max;
                }

                this.setFacetValue(this.range!.start, value);
            }) as EventListener);
        });
    }

    private updateInputElements(start: Date | undefined, end: Date | undefined): void {
        const startElement = this.rootElement.querySelector('[hawksearch-start]') as HTMLInputElement | null;
        const endElement = this.rootElement.querySelector('[hawksearch-end]') as HTMLInputElement | null;

        if (startElement) {
            startElement.value = start ? formatDateIso(start) : '';
        }

        if (endElement) {
            endElement.value = end ? formatDateIso(end) : '';
        }
    }

    private setFacetValue(start: Date | undefined, end: Date | undefined): void {
        if (!!start && !!end && start > end) {
            const tempValue = start;

            start = end;
            end = tempValue;
        }

        if (!start) {
            start = this.range!.start;
        }

        if (!end) {
            end = this.range!.end;
        }

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        this.updateInputElements(start, end);

        if (datesEqual(start, this.range!.start) && datesEqual(end, this.range!.end)) {
            return;
        }

        searchService.setFacetValue(this.data!.field!, `${start?.toISOString() ?? ''},${end?.toISOString() ?? ''}`);
    }
}
