import { BaseComponent } from '@components';
import { HawkSearchComponents, SearchResultsComponentConfig } from '@configuration';
import { PageType, SearchResponse, SearchResultsComponentModel, SearchResultsMode } from '@models';
import { searchService, trackingService } from '@services';
import defaultHtml from './search-results.component.hbs';

/**
 * The Search Results component encapsulates all components relating to search-results.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-search-results>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./search-results.component.hbs}
 *
 * @category Search
 */
export class SearchResultsComponent extends BaseComponent<SearchResultsComponentConfig, SearchResponse, SearchResultsComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'search-results';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    /**
     * Speficies the context that the component is being used. This is determined by the `mode` attribute on the HTML element.
     *
     * @defaultValue `'search-results'`
     */
    protected get mode(): SearchResultsMode {
        switch (this.getAttribute('mode')) {
            case 'landing-page':
                return 'landing-page';
            default:
                return 'search-results';
        }
    }

    /**
     * If {@link mode} is set to `'landing-page'`, this specifies the URL sent to retrieve content from HawkSearch. This can be customized by setting the `url` attribute on the HTML element.
     *
     * @defaultValue `window.location.pathname`
     */
    protected get url(): string {
        return this.getAttribute('url') ?? window.location.pathname;
    }

    constructor() {
        super();

        if (this.mode === 'search-results') {
            trackingService.trackPageLoad(PageType.Custom);

            const searchRequest = searchService.getRequestFromUrl();

            searchService.search(searchRequest);
        }
    }

    protected override getContentModel(): SearchResultsComponentModel {
        return {
            ...this.data!
        };
    }
}
