import { HawkSearchComponents, PopularQueriesContentComponentConfig } from '@configuration';
import { PopularQueriesContent, PopularQueriesContentComponentModel } from '@models';
import { searchService } from '@services';
import { BaseContentComponent } from '../base-content.component';
import defaultHtml from './popular-queries-content.component.hbs';

/**
 * The Popular Queries Content component renders a tag cloud of popular queries relating to the current query. Each link is weighted according to its search frequency, with the most popular queries displayed in the largest font size.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-popular-queries-content>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./popular-queries-content.component.hbs}
 *
 * @category Content
 */
export class PopularQueriesContentComponent extends BaseContentComponent<
    PopularQueriesContentComponentConfig,
    PopularQueriesContent,
    PopularQueriesContentComponentModel
> {
    protected override componentName: keyof HawkSearchComponents = 'popular-queries-content';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data?.items?.length;
    }

    protected override getContentModel(): PopularQueriesContentComponentModel {
        return {
            ...this.data!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-query]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLElement;
                const query = element.getAttribute('hawksearch-query');

                if (query) {
                    searchService.query(query);
                }
            });
        });
    }
}
