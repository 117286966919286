import { HawkSearchGlobal } from '@configuration';

declare let HawkSearch: HawkSearchGlobal;

export function decodeNestedURI(value: string): string {
    let newURI = null;
    newURI = decodeURIComponent(value);
    if (newURI !== value) {
        newURI = decodeNestedURI(newURI);
    }
    return newURI;
}