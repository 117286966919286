import { HawkSearchComponents, LinkListFacetComponentConfig } from '@configuration';
import { LinkListFacetComponentModel, LinkListFacetValue } from '@models';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './link-list-facet.component.hbs';

/**
 * The Linked List Facet component renders a hyperlink for each value.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-link-list-facet>`.
 *
 * ## Event-Binding Attributes
 * *Note: For Event-Binding attributes common to all facet type components, see {@link Components.BaseFacetComponent}.*
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./link-list-facet.component.hbs}
 *
 * @category Facet Types
 */
export class LinkListFacetComponent extends BaseFacetComponent<LinkListFacetComponentConfig, LinkListFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'link-list-facet';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): LinkListFacetComponentModel {
        const filterRegex = this.state.filter ? new RegExp(this.state.filter ?? '', 'i') : undefined;

        return {
            displayCount: this.data!.displayCount ?? true,
            expanded: this.state.toggled ?? false,
            maxHeight: this.data!.scrolling
                ? this.data!.values!.length! >= this.data!.scrolling.threshold
                    ? this.data!.scrolling.height ?? 200
                    : undefined
                : undefined,
            showToggle: !!this.data!.truncation && (this.data!.values?.length ?? 0) > this.data!.truncation.threshold && !this.state.filter,
            values:
                this.data!.values?.map(
                    (v, i): LinkListFacetValue => ({
                        ...v,
                        visible:
                            (!filterRegex && (this.state.toggled || !this.data!.truncation || i < this.data!.truncation.threshold)) ||
                            (!!filterRegex && filterRegex.test(v.title ?? ''))
                    })
                ) ?? [],
            strings: {
                toggle: this.state.toggled ? this.configuration?.strings?.showFewer ?? 'Show fewer' : this.configuration?.strings?.showMore ?? 'Show more'
            }
        };
    }
}
