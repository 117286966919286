import { HawkSearchComponents, SearchWithinFacetComponentConfig } from '@configuration';
import { SearchWithinFacetComponentModel } from '@models';
import { searchService } from '@services';
import { BaseFacetComponent } from '../base-facet.component';
import defaultHtml from './search-within-facet.component.hbs';

/**
 * The Search Within Facet component allows the user to enter keywords to further refine the list of search results.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-search-within-facet>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-input | |
 *
 * When the value of an input element with this attribute changes, that value will be used to refine the list of search results.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./search-within-facet.component.hbs}
 *
 * @category Facet Types
 */
export class SearchWithinFacetComponent extends BaseFacetComponent<SearchWithinFacetComponentConfig, SearchWithinFacetComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'search-within-facet';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): SearchWithinFacetComponentModel {
        return {
            value: this.data!.searchWithin ?? '',
            strings: {
                label: this.configuration?.strings?.label ?? 'Enter keywords'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-input]').forEach((e) => {
            e.addEventListener('change', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLInputElement;
                const value = element.value.trim();

                searchService.searchWithin(value);
            });
        });
    }
}
