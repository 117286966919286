import { HawkSearchGlobal } from '@configuration';
import {
    RawRecommendationsItem,
    RawRecommendationsRequest,
    RawRecommendationsResponse,
    RecommendationsItem,
    RecommendationsRequest,
    RecommendationsResponse
} from '@models';
import { BaseService } from '@services';

declare let HawkSearch: HawkSearchGlobal;

export class RecommendationsService extends BaseService {
    protected baseUrl = HawkSearch.config.recommendations?.endpointUrl || 'https://recs-dev.hawksearch.net';

    // #region Search Operations

    getItems(recommendationsRequest: RecommendationsRequest): Promise<RecommendationsResponse> {
        return this.executeRecommendations(recommendationsRequest);
    }

    private async executeRecommendations(recommendationsRequest: RecommendationsRequest): Promise<RecommendationsResponse> {
        const rawRecommendationsRequest: RawRecommendationsRequest = {
            clientGuid: HawkSearch.config.clientId,
            indexName: HawkSearch.config.index,
            landingPageUrl: recommendationsRequest.url,
            renderHTML: false,
            visitId: this.getVisitId(),
            visitorId: this.getVisitorId(),
            widgetUids: [
                {
                    widgetGuid: recommendationsRequest.widgetId,
                    uniqueid: recommendationsRequest.itemId
                }
            ]
        };

        try {
            this.triggerEvent('hawksearch:before-recommendations-executed', rawRecommendationsRequest);

            const rawRecommendationsResponse = await this.httpPost<RawRecommendationsResponse>(
                '/api/recommendation/v2/getwidgetitems',
                rawRecommendationsRequest
            );

            this.triggerEvent('hawksearch:after-recommendations-executed', rawRecommendationsResponse);

            const recommendationsResponse = this.convertResponse(rawRecommendationsResponse);

            this.triggerEvent('hawksearch:recommendations-completed', recommendationsResponse);

            const key = recommendationsRequest.itemId ? `${recommendationsRequest.widgetId}:${recommendationsRequest.itemId}` : recommendationsRequest.widgetId;

            HawkSearch.recommendationsResponses = {
                ...(HawkSearch.recommendationsResponses ?? {}),
                [key]: recommendationsResponse
            };

            this.bindComponents(recommendationsResponse);

            return recommendationsResponse;
        } catch {
            throw new Error('Error retrieving recommendations response');
        }
    }

    private convertResponse(rawRecommendationsResponse: RawRecommendationsResponse): RecommendationsResponse {
        const convertToArray = (value: string | Array<string> | undefined): Array<string> | undefined => {
            if (!value) {
                return undefined;
            }

            if (typeof value === 'string') {
                return value.split('|^|');
            }

            return value;
        };

        const getItem = (item: RawRecommendationsItem): RecommendationsItem => {
            const variants = this.getVariants(item.customDict);

            return {
                attributes: Object.fromEntries(
                    Object.entries(item.customDict)
                        .filter(([key, value]) => !['hawk_child_attributes', 'hawk_child_attributes_hits'].includes(key))
                        .map(([key, value]) => [key, convertToArray(value as any)])
                ) as any,
                description: item.shortDescription || this.getString(item.customDict, this.fieldMappings.description),
                id: item.id,
                imageUrl: item.imageUrl || this.getUrl(item.customDict, this.fieldMappings.imageUrl, HawkSearch.config.urlPrefixes?.content),
                price: parseFloat(item.price!) || this.getNumber(item.customDict, this.fieldMappings.price) || undefined,
                rating: parseFloat(item.rating!) || this.getNumber(item.customDict, this.fieldMappings.rating) || undefined,
                salePrice: parseFloat(item.salePrice!) || this.getNumber(item.customDict, this.fieldMappings.salePrice) || undefined,
                selectedVariant: variants.selectedItem,
                sku: item.Sku || this.getString(item.customDict, this.fieldMappings.sku),
                title: item.itemName || this.getString(item.customDict, this.fieldMappings.title)!,
                url: item.url || this.getUrl(item.customDict, this.fieldMappings.url, HawkSearch.config.urlPrefixes?.content)!,
                variants: variants.items
            };
        };

        return {
            requestId: rawRecommendationsResponse.requestId,
            widgets: rawRecommendationsResponse?.widgetItems?.map((w) => ({
                id: w.widgetGuid,
                requestId: rawRecommendationsResponse.requestId,
                title: w.widgetName,
                carousel: w.isCarousel,
                items: w.recommendationItems?.map((i) => getItem(i))
            }))
        };
    }

    // #endregion Search Operations

    // #region Events

    bindComponents(recommendationsResponse: RecommendationsResponse): void {
        recommendationsResponse.widgets?.forEach((w) => {
            this.triggerBindEvent('recommendations', w, w.id);
        });
    }

    // #endregion Events
}

export const recommendationsService = new RecommendationsService();
