import { datesEqual, formatDateIso, getDayAbbreviation, getMonth, getMonths } from '@utilities';
import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { DatePickerComponentConfig, HawkSearchComponents } from '@configuration';
import { CalendarDay, DatePickerComponentModel } from '@models';
import defaultHtml from './date-picker.component.hbs';

/**
 * The Date Picker component provides an intuitive interface that allows the user to select a range in ISO format. This component is currently only used within the {@link Components.DateRangeFacetComponent | Date Range Facet Component}.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-date-picker>`.
 *
 * ## Attributes
 * | Name | Value |
 * | :- | :- |
 * | label | `string` |
 * | min | `Date` |
 * | max | `Date` |
 * | value | `Date` |
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-input | |
 *
 * The input element must have this attribute. When this element is clicked, the date picker modal will be displayed.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-modal | |
 *
 * This root element of the date picker modal must have this attribute to calculate modal positioning.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-month | |
 *
 * This attribute is used to identify the form element used to select a specific month.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-year | |
 *
 * This attribute is used to identify the form element used to select a specific year.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-select-month | |
 *
 * When an element with this attribute is clicked, the month specified by `hawksearch-month` and `hawksearch-year` to be displayed in the date picker modal.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-previous | |
 *
 * When an element with this attribute is clicked, the previous month will be displayed in the date picker modal.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-next | |
 *
 * When an element with this attribute is clicked, the next month will be displayed in the date picker modal.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-month-selector | |
 *
 * When an element with this attribute is clicked, the month selector will be displayed in the date picker modal to quickly jump to a particular month rather than using pagination.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-date | `string` (ISO date format) |
 *
 * When an element with this attribute is clicked, the specified date will be selected and displayed in the input element.
 *
 * ## Events
 * | Name | Data Type |
 * | :- | :- |
 * | hawksearch:date-picker-changed | `Date` |
 *
 * This event fires whenever the user selects a date.
 *
 * ## Handlebars Helpers
 * | Name | Parameter |
 * | :- | :- |
 * | dayOfMonth | `Date` |
 *
 * This helper function returns the zero-padded day of the month from a provided date object.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./date-picker.component.hbs}
 *
 * @category General
 */
export class DatePickerComponent extends BaseComponent<DatePickerComponentConfig, never, DatePickerComponentModel> {
    static get observedAttributes(): Array<string> {
        return ['label', 'max', 'min', 'value'];
    }

    protected override componentName: keyof HawkSearchComponents = 'date-picker';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = false;

    private label?: string;
    private minDate?: Date;
    private maxDate?: Date;
    private selectedDate?: Date;
    private modalVisible = false;
    private modalDate = new Date();
    private monthSelectorVisible = false;
    private documentClickEventHandler!: (event: MouseEvent) => void;
    private windowResizeEventHandler!: (event: Event) => void;

    override connectedCallback(): void {
        super.connectedCallback();

        this.documentClickEventHandler = (event: MouseEvent): void => {
            if (!this.modalVisible || event.composedPath().includes(this)) {
                return;
            }

            this.modalVisible = false;

            setTimeout(() => this.render());
        };

        document.addEventListener('click', this.documentClickEventHandler);

        this.windowResizeEventHandler = debounce((event: Event): void => {
            this.positionModal();
        }, 100);

        window.addEventListener('resize', this.windowResizeEventHandler);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        document.removeEventListener('click', this.documentClickEventHandler);
        window.addEventListener('resize', this.windowResizeEventHandler);
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
        if (name === 'label') {
            this.label = newValue || undefined;
        } else {
            const date = newValue ? new Date(newValue) : undefined;

            if (date) {
                date.setHours(0, 0, 0, 0);
            }

            switch (name) {
                case 'max':
                    this.maxDate = date;
                    break;
                case 'min':
                    this.minDate = date;
                    break;
                case 'value':
                    this.selectedDate = date;
                    break;
            }
        }

        this.render();
    }

    protected override registerHelpers(): void {
        super.registerHelpers();

        this.handlebars.registerHelper('dayAbbreviation', (day: number): string => {
            return getDayAbbreviation(day);
        });

        this.handlebars.registerHelper('dayOfMonth', (date: Date): string => {
            return date.getDate().toString().padStart(2, '0');
        });
    }

    protected override getContentModel(): DatePickerComponentModel {
        return {
            value: this.selectedDate ? formatDateIso(this.selectedDate) : '',
            modalVisible: this.modalVisible,
            monthSelectorVisible: this.monthSelectorVisible,
            years: this.getYears(),
            months: getMonths(),
            weeks: this.getWeeks(this.modalDate),
            currentYear: this.modalDate.getFullYear(),
            currentMonth: getMonth(this.modalDate),
            strings: {
                heading: `${getMonth(this.modalDate)} ${this.modalDate.getFullYear()}`,
                label: this.label ?? this.configuration?.strings?.defaultLabel ?? 'Selected Date',
                next: this.configuration?.strings?.next ?? 'Next',
                previous: this.configuration?.strings?.previous ?? 'Previous',
                selectMonth: this.configuration?.strings?.selectMonth ?? 'Select Month',
                viewCalendar: this.configuration?.strings?.viewCalendar ?? 'View Calendar'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.positionModal();

        this.rootElement.querySelectorAll('[hawksearch-input]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                this.modalVisible = true;
                this.modalDate = this.selectedDate ? new Date(this.selectedDate) : new Date();

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-previous]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                this.modalDate.setDate(1);
                this.modalDate.setMonth(this.modalDate.getMonth() - 1);

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-next]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                this.modalDate.setDate(1);
                this.modalDate.setMonth(this.modalDate.getMonth() + 1);

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-month-selector]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                this.monthSelectorVisible = true;

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-select-month]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const month = (this.rootElement.querySelector('[hawksearch-month]') as HTMLSelectElement | null)?.value;
                const year = (this.rootElement.querySelector('[hawksearch-year]') as HTMLSelectElement | null)?.value;

                if (month === undefined) {
                    throw new Error('Unable to get month value');
                }

                if (year === undefined) {
                    throw new Error('Unable to get year value');
                }

                this.modalDate = new Date(parseInt(year), parseInt(month), 1);
                this.monthSelectorVisible = false;

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-date]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();

                const value = e.getAttribute('hawksearch-date') || undefined;

                if (!value) {
                    return;
                }

                const date = new Date(value);

                if (!((!this.minDate || date >= this.minDate) && (!this.maxDate || date <= this.maxDate))) {
                    return;
                }

                this.selectedDate = date;
                this.modalVisible = false;

                this.render();

                const newEvent = new CustomEvent('hawksearch:date-picker-changed', {
                    detail: date
                });

                this.dispatchEvent(newEvent);
            });
        });
    }

    private positionModal(): void {
        const modal = this.rootElement.querySelector('[hawksearch-modal]') as HTMLElement;

        if (!modal || !modal.offsetWidth || !this.modalVisible) {
            return;
        }

        modal.style.left = '';
        modal.style.right = '';

        const windowWidth = window.innerWidth;
        const modalBoundaries = modal.getBoundingClientRect();

        if (modal.offsetWidth + modalBoundaries.x >= windowWidth && windowWidth >= modal.offsetWidth) {
            modal.style.right = '0';
        }
    }

    private getYears(): Array<number> {
        const years: Array<number> = [];
        const currentYear = this.modalDate.getFullYear();
        const minYear = currentYear < 1900 ? currentYear : 1900;
        const maxYear = (currentYear > new Date().getFullYear() ? currentYear : new Date().getFullYear()) + 10;

        for (let i = minYear; i <= maxYear; i++) {
            years.push(i);
        }

        return years;
    }

    private getWeeks(date: Date): Array<Array<CalendarDay>> {
        let startDate = new Date(date.getFullYear(), date.getMonth(), 1);

        // Set date to first day of week containing first of month
        startDate.setDate(startDate.getDate() - startDate.getDay());

        const weeks: Array<Array<CalendarDay>> = [];

        do {
            const week = this.getWeek(startDate);

            weeks.push(week);

            startDate = new Date(weeks[weeks.length - 1][6].date.getTime());
            startDate.setDate(startDate.getDate() + 1);
        } while (weeks.length < 6);

        return weeks;
    }

    private getWeek(startDate: Date): Array<CalendarDay> {
        const week: Array<any> = [];
        const today = new Date();

        today.setHours(0, 0, 0, 0);

        for (let i = 0; i < 7; i++) {
            const date = new Date(startDate.getTime());

            date.setDate(date.getDate() + i);
            date.setHours(0, 0, 0, 0);

            const day: CalendarDay = {
                date: date,
                value: date.toISOString(),
                currentMonth: date.getMonth() === this.modalDate.getMonth(),
                enabled: (!this.minDate || date >= this.minDate) && (!this.maxDate || date <= this.maxDate),
                selected: this.selectedDate ? datesEqual(date, this.selectedDate) : false
            };

            week.push(day);
        }

        return week;
    }
}
