import { HawkSearchComponents, ImageContentComponentConfig } from '@configuration';
import { ImageContent, ImageContentComponentModel } from '@models';
import { trackingService } from '@services';
import { BaseContentComponent } from '../base-content.component';
import defaultHtml from './image-content.component.hbs';

/**
 * The Image Content component displays an image defined in HawkSearch.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-image-content>`.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./image-content.component.hbs}
 *
 * @category Content
 */
export class ImageContentComponent extends BaseContentComponent<ImageContentComponentConfig, ImageContent, ImageContentComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'image-content';
    protected override defaultHtml = defaultHtml;

    protected override renderContent(): boolean {
        return !!this.data;
    }

    protected override getContentModel(): ImageContentComponentModel {
        return {
            ...this.data!
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-link]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                trackingService.trackBannerClick(this.data!.id, this.data!.campaignId);
            });
        });

        trackingService.trackBannerImpression(this.data!.id, this.data!.campaignId);
    }
}
