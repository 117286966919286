import { BaseComponent } from '@components';
import { HawkSearchComponents, SortingComponentConfig } from '@configuration';
import { Sorting, SortingComponentModel } from '@models';
import { searchService } from '@services';
import defaultHtml from './sorting.component.hbs';

/**
 * The Sorting component is used to select the logic for ordering search results.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-sorting>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-sort | |
 *
 * When a `select` element with this attribute changes in value, the search will be updated to use that value as the new sort order.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./sorting.component.hbs}
 *
 * @category Search
 */
export class SortingComponent extends BaseComponent<SortingComponentConfig, Sorting, SortingComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'sorting';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    protected override renderContent(): boolean {
        return !!this.data?.options?.length;
    }

    protected override getContentModel(): SortingComponentModel {
        return {
            options: this.data!.options
        };
    }

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('[hawksearch-sort]').forEach((e) => {
            e.addEventListener('change', (event: Event) => {
                event.preventDefault();

                const element = event.currentTarget as HTMLSelectElement;
                const value = element.value;

                searchService.sort(value);
            });
        });
    }
}
