import { BaseComponent } from '@components';
import { BaseComponentConfig, HawkSearchGlobal } from '@configuration';
import { BaseComponentModel, Facet, FacetState } from '@models';
import { searchService } from '@services';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Base Facet component is inherited by all facet type copmonents.
 *
 * ## Event-Binding Attributes
 * The following attributes are common to all facet type components. Individual facet types may support additional attribute bindings.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-value | `string` (if applicable) |
 *
 * When a checkbox input element with this attribute is checked or unchecked, the value of that element will be added or removed from the search request as appropriate. For non-input elements, the attribute value will be used instead of a form element value.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-value-exclude | `string` |
 *
 * When an element with this attribute is clicked, search results with the corresponding attribute value will be excluded from search results.
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-value-include | `string` |
 *
 * When an element with this attribute is clicked, search results with the corresponding attribute value will no longer be excluded from search results.
 *
 * *Note: Elements with this attribute value should only be displayed for facet values that are excluded.*
 *
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-facet-value-toggle | |
 *
 * When an element with this attribute is clicked, child facet values will be displayed or hidden as appropriate.
 *
 * *Note: This attribute should only be used for facets that support nested values.*
 *
 * @category Facet Types
 */
export abstract class BaseFacetComponent<TConfig extends BaseComponentConfig, TModel extends BaseComponentModel> extends BaseComponent<TConfig, Facet, TModel> {
    protected override bindFromEvent = false;

    state!: FacetState;

    protected override onRender(): void {
        super.onRender();

        this.rootElement.querySelectorAll('input[hawksearch-facet-value]').forEach((e) => {
            e.addEventListener('change', (event: Event) => {
                event.stopPropagation();

                const element = event.currentTarget as HTMLInputElement;
                const field = this.data!.field!;
                const value = element.value;

                if (element.checked) {
                    searchService.addFacetValue(field, value);
                } else {
                    searchService.removeFacetValue(field, value);
                }
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-value]:not(input)').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                const element = event.currentTarget as HTMLElement;
                const value = element.getAttribute('hawksearch-facet-value');
                const facetValue = this.data!.values?.find((v) => v.value === value);

                if (!facetValue) {
                    return;
                }

                if (facetValue.selected) {
                    searchService.removeFacetValue(this.data!.field!, value!);
                } else {
                    searchService.addFacetValue(this.data!.field!, value!);
                }
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-value-include]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                const element = event.currentTarget as HTMLElement;
                const field = this.data!.field!;

                let value = element.getAttribute('hawksearch-facet-value-include');

                if (!value) {
                    return;
                }

                value = `${HawkSearch.config.search?.facetExclusionPrefix || '-'}${value}`;

                searchService.removeFacetValue(field, value);
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-value-exclude]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                const element = event.currentTarget as HTMLElement;
                const field = this.data!.field!;
                const value = element.getAttribute('hawksearch-facet-value-exclude');

                if (!value) {
                    return;
                }

                searchService.excludeFacetValue(field, value);
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-value-toggle]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                const element = event.currentTarget as HTMLElement;
                const value = element.getAttribute('hawksearch-facet-value-toggle');

                if (!value) {
                    return;
                }

                this.state.values![value].toggled = !this.state.values![value].toggled;

                this.render();
            });
        });

        this.rootElement.querySelectorAll('[hawksearch-facet-toggle]').forEach((e) => {
            e.addEventListener('click', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                this.state.toggled = !this.state.toggled;

                this.render();
            });
        });
    }
}
