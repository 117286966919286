import { debounce } from 'lodash-es';
import { BaseComponent } from '@components';
import { HawkSearchComponents, HawkSearchGlobal, SearchFieldComponentConfig } from '@configuration';
import { AutocompleteResponse, SearchFieldComponentModel, SearchResponse } from '@models';
import { autocompleteService, searchService } from '@services';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import defaultHtml from './search-field.component.hbs';

declare let HawkSearch: HawkSearchGlobal;

/**
 * The Search component contains a text input element which triggers the {@link Components.AutocompleteComponent | Autocomplete component} as the user types and executes a new search when the user hits the `enter` key.
 *
 * ## Tag
 * The tag for this component is `<hawksearch-search-field>`.
 *
 * ## Event-Binding Attributes
 * | Name | Value |
 * | :- | :- |
 * | hawksearch-input | |
 *
 * Input elements with this attribute will trigger {@link Components.AutocompleteComponent | Autocomplete} functionality when the user types and executes a new search when the user hits the `enter` key.
 *
 * ## Default Template
 * The following is the default Handlebars template for this component. To create a custom template, it is recommended to use this as a starting point.
 * {@embed ./search-field.component.hbs}
 *
 * @category Search
 */
export class SearchFieldComponent extends BaseComponent<SearchFieldComponentConfig, SearchResponse, SearchFieldComponentModel> {
    protected override componentName: keyof HawkSearchComponents = 'search-field';
    protected override defaultHtml = defaultHtml;
    protected override bindFromEvent = true;

    private clickEventHandler!: (event: MouseEvent) => void;
    private defaultAutocompleteResponse?: AutocompleteResponse | false;
    private previousAutocompleteResponse?: AutocompleteResponse;
    private previousValue = '';

    private get autocompleteMinCharacterCount(): number {
        return HawkSearch.config.autocomplete?.minCharacterCount ?? 1;
    }

    private get recommendationsEnabled(): boolean {
        return HawkSearch.config.autocomplete?.recommendationsEnabled ?? false;
    }

    private get disableAutofill(): boolean {
        return HawkSearch.config.components?.['search-field']?.disableAutofill ?? false;
    }

    override connectedCallback(): void {
        super.connectedCallback();

        this.clickEventHandler = (event: Event): void => {
            const element = event.target as HTMLElement;
            const searchElement = !!element.closest('hawksearch-search-field');

            if (!searchElement) {
                this.toggleAutocomplete(false);
            }
        };

        this.rootElement.ownerDocument!.addEventListener('click', this.clickEventHandler);
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();

        this.rootElement.ownerDocument!.removeEventListener('click', this.connectedCallback);
    }

    protected override getContentModel(): SearchFieldComponentModel {
        return {
            query: this.data?.query,
            strings: {
                placeholder: this.configuration?.strings?.placeholder ?? 'Enter keywords'
            }
        };
    }

    protected override onRender(): void {
        super.onRender();

        const enterKeys = ['Enter', 'NumpadEnter'];

        this.rootElement.querySelectorAll('[hawksearch-input]').forEach((e) => {
            if (this.disableAutofill) {
                const element = e as HTMLInputElement;
                element.value = '';
            }

            e.addEventListener('focus', ((event: FocusEvent): void => {
                const element = e as HTMLInputElement; // Not using event.currentTarget as that gets lost when using lodash debounce
                const query = element.value.trim();

                if (query && this.previousAutocompleteResponse) {
                    autocompleteService.bindComponent(this.previousAutocompleteResponse);
                } else if (!query) {
                    this.displayDefaultAutocomplete();
                }
            }) as EventListener);

            e.addEventListener('keyup', ((event: KeyboardEvent): void => {
                if (!enterKeys.includes(event.code)) {
                    return;
                }

                const element = event.currentTarget as HTMLInputElement;
                const query = element.value.trim();

                this.toggleAutocomplete(false);

                searchService.query(query);
            }) as EventListener);

            const onKeyUp = async (event: KeyboardEvent): Promise<void> => {
                const element = e as HTMLInputElement; // Not using event.currentTarget as that gets lost when using lodash debounce
                const query = element.value.trim();

                if (enterKeys.includes(event.code) || query === this.previousValue) {
                    return;
                }

                this.previousValue = query;

                this.toggleAutocomplete(!!query);

                if (query && query.length > this.autocompleteMinCharacterCount) {
                    this.previousAutocompleteResponse = await autocompleteService.query(query);
                } else {
                    this.displayDefaultAutocomplete();
                }
            };

            e.addEventListener('keyup', debounce(onKeyUp, 250) as unknown as EventListener);
        });

        this.rootElement.querySelectorAll('hawksearch-autocomplete').forEach((e) => {
            e.addEventListener('hawksearch:close-autocomplete', (event: Event) => {
                this.toggleAutocomplete(false);
            });
        });
    }

    private toggleAutocomplete(visible: boolean): void {
        this.rootElement.querySelectorAll('hawksearch-autocomplete').forEach((e) => {
            const component = e as AutocompleteComponent;

            component.style.display = visible ? 'block' : 'none';
        });
    }

    private async displayDefaultAutocomplete(): Promise<void> {
        if (!this.recommendationsEnabled || this.defaultAutocompleteResponse === false) {
            return;
        }

        if (this.defaultAutocompleteResponse === undefined) {
            const autocompleteResponse = await autocompleteService.query();

            this.defaultAutocompleteResponse = autocompleteResponse || false;
        }

        if (!this.defaultAutocompleteResponse) {
            return;
        }

        autocompleteService.bindComponent(this.defaultAutocompleteResponse);
    }
}
