import { HawkSearchGlobal } from '@configuration';

declare let HawkSearch: HawkSearchGlobal;

export function datesEqual(date1: Date, date2: Date): boolean {
    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return date1.getTime() === date2.getTime();
}

export function getDayAbbreviation(day: number): string {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';
    const date = new Date(2023, 0, day + 1); // Starting with January 1, 2023 since that starts with Sunday

    return date.toLocaleDateString(cultureIsoCode, { weekday: 'short' });
}

export function getMonth(date: Date): string {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';

    return date.toLocaleDateString(cultureIsoCode, { month: 'long' });
}

export function getMonths(): Array<string> {
    const cultureIsoCode = HawkSearch.config.formatting?.cultureIsoCode || 'en-US';
    const year = new Date().getFullYear();
    const months: Array<string> = [];

    for (let i = 0; i < 12; i++) {
        const date = new Date(year, i, 1);
        const month = date.toLocaleDateString(cultureIsoCode, { month: 'long' });

        months.push(month);
    }

    return months;
}
